<!-- @component A call-to-action. -->
<script lang="ts">
  import { ArrowRight } from "lucide-svelte";

  export let primary = false;
  export let large = false;

  /* props for <a> */
  export let href: string | undefined = undefined;
  export let target: string | undefined = undefined;
  export let rel: string | undefined = undefined;
</script>

{#if href}
  <a
    {href}
    class="btn btn-outlined group whitespace-normal"
    class:btn-lg={large}
    class:btn-primary={primary}
    {rel}
    {target}
    on:click
  >
    <slot />
    <ArrowRight
      size={18}
      class="ml-1 opacity-80 group-hover:opacity-100 transition-opacity"
    />
  </a>
{:else}
  <button
    class="btn btn-outlined group"
    class:btn-lg={large}
    class:btn-primary={primary}
    on:click
  >
    <slot />
    <ArrowRight
      size={18}
      class="ml-1 opacity-80 group-hover:opacity-100 transition-opacity"
    />
  </button>
{/if}
